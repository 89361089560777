<script setup lang="ts">
import { useGeneralStore } from "~/stores/generalStore";

const { locale } = useI18n();
const store = useGeneralStore();

const availableLocales = {
  en: {
    name: "English",
    iso: "en",
    flag: "🇺🇸",
  },
  fr: {
    name: "Français",
    iso: "fr",
    flag: "🇫🇷",
  },
};

watch(locale, (newLang) => {
  locale.value = newLang;
  store.setLocale(newLang);
});

onMounted(() => {
  locale.value = store.lang;
});
</script>

<template>
  <select
    class="bg-primary text-primary border-none px-2 focus:outline-none p-0 rounded-md"
    v-model="locale"
  >
    <option
      v-for="(locale, key) in availableLocales"
      :key="key"
      :value="locale.iso"
    >
      {{ locale.flag }} {{ locale.name }}
    </option>
  </select>
</template>

<style scoped lang="scss"></style>
